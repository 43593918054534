@import "~@ng-select/ng-select/themes/material.theme.css";
/* start common */
:root {
    --color-light: 248, 249, 255;
    --color-blue: 0, 127, 255;
    --color-blue2: 7, 87, 167;
    --color-blue3: 76, 80, 134; // #4C5086
    --color-dark: 68, 68, 79;
    --color-orange: 251, 142, 5;
    --color-orange2: 252, 193, 86;
    --color-orange3: 251, 139, 35; // #FB8E25
    --color-mouve: 82, 87, 152; // #525798
    --color-yellowGreen: 188, 170, 92; //#BCAA5C
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #ddd;
}
::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    // -webkit-user-select: none;
    // -khtml-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
}
html,
body {
    max-width: 100%;
    overflow-x: hidden;
}
body {
    background: rgba(var(--color-light), 1);
}
.transition-2 {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
@media (max-width: 992px) {
    .container {
        width: 100%;
    }
}
/* end common */

/* start modal-section */
.modal-section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -99999;
    .overlay {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.75);
    }
    &.open {
        z-index: 99999;
        opacity: 1;
    }
    .contain {
        border-radius: 10px;
        position: absolute;
        z-index: 2;
        max-height: 100vh;
        overflow-y: auto;
        background: #fff;
        max-width: 651px;
        position: absolute;
        top: 15px;
        bottom: 15px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        padding-top: 50px;
        & > i {
            position: absolute;
            top: 0;
            right: 0;
            color: #999;
            font-size: 20px;
            padding: 20px;
            cursor: pointer;
            &:hover {
                color: #ddd;
            }
        }
    }
}
/*end modal-section */
.fa-share-alt {
    cursor: pointer;
}

// Start Pagination style
/* ***** start pagination styles */

.pagination-container {
    display: flex;
    justify-content: flex-end;
    .pagination {
        .pagination-page {
            min-width: 37px;
            text-align: center;
        }
        .pagination-prev,
        .pagination-next {
            min-width: 47px;
        }

        .page-item:first-child .page-link,
        .page-item:last-child .page-link,
        .page-link,
        .page-item:first-child .page-link,
        .page-item:last-child .page-link {
            text-align: center !important;
            color: #44444f;
            border: 0.15px solid #e2e2e2;
            font-weight: 600;
            font-size: 15px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .page-link:hover,
        .page-item.active .page-link,
        .page-link:hover {
            background-color: #ebebeb;
            color: #44444f;
            font-weight: 600;
            border: 0.15px solid #e2e2e2;
            font-size: 15px;
        }
        .page-item:first-child .page-link,
        .page-item:last-child .page-link {
            border-start-start-radius: 5px;
            border-end-start-radius: 5px;
            border: none;
            background-color: rgba(var(--color-orange2), 1);
            font-size: 20px;
        }

        .page-item.disabled .page-link,
        .page-item.disabled .page-link,
        .page-item.disabled .page-link {
            text-align: center;
            background-color: rgba(var(--color-orange2), 1);
            font-weight: 600;
            color: #44444f;
        }
    }
}

.bs-rating-star {
    font-size: 30px;
}

@media only screen and (max-width: 767px) {
    .pagination-prev,
    .pagination-next {
        min-width: 42px;
    }

    .pagination-page {
        min-width: 34px;
        text-align: center;
    }

    html[lang="ar"] .page-link,
    .page-item.active .page-link,
    .page-link,
    .page-item.active .page-link {
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* ***** End pagination styles */
.ngx-slider {
    margin-top: 0 !important;
    .ngx-slider-bar {
        height: 20px !important;
        border-radius: 10px !important;
    }
}
.ngx-slider.animate .ngx-slider-tick,
.ngx-slider .ngx-slider-tick,
.ngx-slider .ngx-slider-tick.ngx-slider-selected {
    // background: yellow;
    height: 20px;
    background: white;
    margin-top: 3px;
    width: 1px;
}
.ngx-slider .ngx-slider-pointer {
    width: 25px !important;
    height: 25px !important;
    top: -4px !important;
    // left: -2px !important;
}
.ngx-slider .ngx-slider-pointer:after {
    display: none !important;
}
.ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background-color: white !important;
}
.ngx-slider-inner-tooltip {
    display: none !important;
}
.ngx-slider .ngx-slider-bubble {
    top: -30px;
}
.rate-row {
    .ngx-slider {
        width: 60% !important;
    }
}

.message-error .fa-times-circle{
    font-size: 30px;
}
/****** start my reviews taps *****/
.nav-tabs .nav-link {
    color: white;
    font-size: 20px;
    background-color: rgba(var(--color-mouve), 1);
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    &.active {
        background-color: rgba(var(--color-orange2), 1);
    }
}

/****** end my reviews taps *****/
/*******  start ng-select ********/
.ng-select:not(.search-select) {
    padding-bottom: 5px;
}
.ng-select:not(.search-select) .ng-select-container {
    width: 100%;
    padding-left: 25px;
    outline: 0;
    border: 1px solid #ddd;
    border-radius: 20px;
    font-size: 14px;
    min-height: 40px;
}
.ng-select .ng-select-container .ng-value-container, .ng-select .ng-select-container:after {
    border: 0;
}
.ng-select:not(.search-select).ng-select-container .ng-value-container .ng-input>input {
    text-indent: 24px;
}
.ng-dropdown-panel.ng-select-bottom {
    top: 40px;
}

.search-select {
    width: 200px;
    border-radius: 20px 0 25px 20px;
    outline: 0;
    border: 0;
    text-align: center;
    font-size: 14px; 
    padding-bottom: 0;
    cursor: pointer;
    .ng-select-container {
        min-height: 35px;
    }
    .ng-select-container .ng-value-container {
        padding-top: 8px;
    }
    .ng-dropdown-panel.ng-select-bottom {
        top: 40px;
    }
}
.search-box.white {
    .search-select {
        border-right: 10px solid #fff;
    }
    .search-select,
    ng-option {
        color: rgba( var(--color-mouve), 1 );
        background: #fff;
    }
    .ng-value-label,
    .ng-arrow-wrapper .ng-arrow, 
    .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
        color: rgba( var(--color-mouve), 1 );
    }
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        padding: 0 10px;
        color: rgba( var(--color-mouve), 1 );
    }
}
.search-box.orange {
    .search-select {border-right: 10px solid rgba(var(--color-orange3), 1);}
    .search-select {
        color: #fff;
        background: rgba( var(--color-orange3), 1 );
    }
    .ng-value-label,
    .ng-arrow-wrapper .ng-arrow,
    .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
        color: #FFF;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items {

        background: rgba( var(--color-orange3), 1 );
    }
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        color: #fff;
        padding: 0 10px;
    }
}
@media(max-width: 500px) {
    .ng-value, .ng-option-label {
        font-size: 12px;
    }
}
.job-filter-select.ng-select.ng-select-single .ng-select-container .ng-value-container {
    margin-top: 2px;
}
// ng select in new crew page
.review_system_new-crew .ng-select .ng-select-container{
    min-height: 50px;
    border-radius: 25px;
}
.review_system_new-crew .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    bottom: 13px;
}
.review_system_new-crew .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    bottom: 10px;
}
.review_system_new-crew .ng-select-container.ng-has-value {
    align-items: center;
}
/*******  end ng-select ********/

.ng-option{
    min-width: 200px;
}